import { Image } from 'cms-types'

import LoadingText from '../../components/LoadingText'
import { PayloadImage } from '../../components/PayloadImage'
import type { LoadableComponentProps } from '../../types'

type SetGuideProps = {
  title: string
  description: string
  image: Image
}

function SetGuide({ data, loading }: LoadableComponentProps<SetGuideProps>) {
  return (
    <div className='radius-lg m-5 flex h-[480px] flex-col overflow-hidden bg-lightgray sm:flex-row lg:m-10'>
      <div className='w-full flex-1 sm:w-[415px] sm:flex-none lg:w-[670px]'>
        <PayloadImage
          src={data?.image}
          sizes='(min-width: 1024px) 670px, 415px'
          objectFit='cover'
          showPlaceholder={loading}
        />
      </div>
      <div className='sm:flex-1'>
        <div className='flex h-full flex-col justify-center gap-7 px-5 py-6 text-center sm:px-20 sm:py-0 sm:text-left'>
          <h4>{loading ? <LoadingText className='w-[80%]' /> : data.title}</h4>
          <p className='md'>{loading ? <LoadingText className='w-[90%]' /> : data.description}</p>
        </div>
      </div>
    </div>
  )
}

export default SetGuide
