import { type Image } from 'cms-types'

import { LoadableComponentProps } from '../../types'
import LoadingText from '../LoadingText'
import { PayloadImage } from '../PayloadImage'

type Props = LoadableComponentProps<{
  title: string
  description: (JSX.Element | null)[]
  productFeatures: {
    id: string | null | undefined
    text: string
    image: Image | undefined
  }[]
}>

export default function PhilosophieValues({ loading, data }: Props) {
  return (
    <section className='bg-lightgray px-5 py-16 xl:px-10 xl:py-20'>
      <div className='radius-lg flex flex-col gap-y-12 bg-white px-5 py-8 xl:flex-row xl:gap-[120px] xl:px-20 xl:py-20'>
        <div className='flex basis-[50%] flex-col gap-y-5 xl:justify-center xl:gap-6'>
          <h2 className='h4'>{loading ? <LoadingText textLength={15} /> : data.title}</h2>

          <div className='[&>*:last-child]:!mb-0'>
            {loading ? <LoadingText textLength={25} lines={5} /> : data.description}
          </div>
        </div>

        <ul className='grid basis-[50%] gap-6 md:grid-cols-2 xl:grid-cols-1'>
          {loading
            ? new Array(4).fill(null).map((_, i) => (
                <li key={`loading-${i}`} className='flex items-center gap-5'>
                  <PayloadImage
                    sizes='(min-width: 1280px) 206px, 160px'
                    className='radius-md !h-20 !w-20 shrink-0 overflow-hidden xl:!h-[103px] xl:!w-[103px] xl:!rounded-lg'
                    objectFit='cover'
                    showPlaceholder
                  />
                  <span>
                    <LoadingText textLength={25} />
                  </span>
                </li>
              ))
            : data.productFeatures?.map(({ id, text, image }) => (
                <li key={id} className='flex items-center gap-5'>
                  <PayloadImage
                    src={image}
                    sizes='(min-width: 1280px) 206px, 160px'
                    className='radius-md !h-20 !w-20 shrink-0 overflow-hidden xl:!h-[103px] xl:!w-[103px] xl:!rounded-lg'
                    objectFit='cover'
                  />
                  <span>{text}</span>
                </li>
              ))}
        </ul>
      </div>
    </section>
  )
}
