import Link from 'next/link'

import Button from '../../components/Button'
import LoadingText from '../../components/LoadingText'
import { PayloadImage } from '../../components/PayloadImage'
import { BlogTeaserSlide, LoadableComponentProps } from '../../types'

type BlogTeaserProps = LoadableComponentProps<{
  title: string
  linkToBlogText: string
  linkToBlogURL: string
  slides: BlogTeaserSlide[]
}>

function BlogTeaser({ loading, data }: BlogTeaserProps) {
  if (!loading && !data?.slides.length) {
    return
  }

  return (
    <div className='flex w-full flex-col px-5 py-10 lg:py-20 xl:px-10'>
      <div className='mb-14 flex flex-row items-center'>
        <h2 className='h2 flex-1'>{loading ? <LoadingText textLength={15} /> : data.title}</h2>

        <Link
          href={data?.linkToBlogURL ?? '#'}
          className='hidden no-underline transition-colors duration-300 hover:text-darkgray lg:block'
        >
          <p className='xl:underline xl:underline-offset-8'>
            {loading ? <LoadingText textLength={15} /> : data.linkToBlogText}
          </p>
        </Link>
      </div>

      <div className='grid w-full grid-cols-1 gap-5 md:-mr-5 md:flex md:grid-cols-2 md:flex-row md:!overflow-x-scroll xl:grid'>
        {loading
          ? new Array(2)
              .fill(null)
              .map((_, index) => <BlogTeaserSlideComponent key={`loading-${index}`} loading />)
          : data.slides.map(data => (
              <BlogTeaserSlideComponent key={data.id ?? data.linkText} data={data} />
            ))}
      </div>

      <Button
        isLoading={loading}
        className='mt-8 block lg:hidden'
        href={data?.linkToBlogURL}
        appearance='transparent'
      >
        {data?.linkToBlogText}
      </Button>
    </div>
  )
}

const BlogTeaserSlideComponent = ({ loading, data }: LoadableComponentProps<BlogTeaserSlide>) => {
  return (
    <div className='group relative col-span-1 h-0 w-full pb-[100%] md:w-[640px] md:flex-shrink-0 md:pb-[640px] xl:w-full xl:pb-[100%]'>
      <div className='absolute left-1/2 top-0 h-full w-full -translate-x-1/2 transform overflow-hidden rounded-md'>
        <PayloadImage
          sizes='(min-width: 768px) 640px, (min-width: 1280px) 50vw, 100vw'
          src={data?.blogPost.preview.image}
          objectFit='cover'
          className='block transition-all duration-[0.8s] ease-[ease] group-hover:scale-105'
          showPlaceholder={loading}
        />
      </div>

      <div
        className={`absolute bottom-0 w-full rounded-md bg-gradient-to-t via-70% ${
          data?.lightBackgroundOverlay
            ? 'from-lightgray/80 via-lightgray/40'
            : 'from-darkblack/80 via-darkblack/40'
        } px-4 py-5 xl:px-8 xl:py-12  ${data?.textColor ?? ''}`}
      >
        <h6 className='h6 mb-2'>
          {loading ? <LoadingText textLength={15} /> : data.blogPost.preview.subtitle}
        </h6>

        <h4 className='h4 mb-6'>
          {loading ? <LoadingText textLength={15} /> : data.blogPost.title}
        </h4>

        {/* TODO remove prefetch when post pages will be done */}
        <Link
          href={loading ? '#' : `/post/${data.blogPost.slug}`}
          prefetch={false}
          className='no-underline'
        >
          <p className='underline-animated'>
            {loading ? <LoadingText textLength={10} /> : data.linkText}
          </p>
        </Link>
      </div>
    </div>
  )
}

export default BlogTeaser
