import clsx from 'clsx'

import LoadingText from '../../components/LoadingText'
import { PayloadImage } from '../../components/PayloadImage'
import { PayloadVideo } from '../../components/PayloadVideo'
import isPayloadImage from '../../helpers/isPayloadImage'
import type { LoadableComponentProps, Media } from '../../types'

type ContentGridProps = LoadableComponentProps<
  {
    title: string
    description: (JSX.Element | null)[]
    media: Media | undefined
  }[]
>

export default function ContentGrid({ loading, data }: ContentGridProps) {
  return (
    <div className='flex flex-col gap-5 px-5 xl:px-10 xl:py-20'>
      {loading
        ? new Array(3)
            .fill(null)
            .map((_, index) => <SingleCard key={`loading-${index}`} loading index={index} />)
        : data.map((data, index) => (
            <SingleCard key={`${data.title}-${index}`} data={data} index={index} />
          ))}
    </div>
  )
}

const SingleCard = ({
  loading,
  data,
  index,
}: LoadableComponentProps<NonNullable<ContentGridProps['data']>[number]> & { index: number }) => (
  <div className={clsx('flex flex-col gap-5', ['lg:flex-row', 'lg:flex-row-reverse'][index % 2])}>
    {loading || isPayloadImage(data.media) ? (
      <PayloadImage
        sizes='100vw'
        src={data?.media}
        className='radius-lg !h-[335px] overflow-hidden lg:!h-[unset] lg:grow-[1] lg:basis-[50%]'
        objectFit='cover'
        showPlaceholder={loading}
      />
    ) : (
      <PayloadVideo
        autoPlay
        src={data?.media}
        containerClassName='lg:grow-[1] lg:basis-[50%] [&>div]:lg:h-full'
        className='radius-lg h-[335px] w-full cursor-default overflow-hidden object-cover lg:h-full '
      />
    )}
    <div
      className={clsx(
        'radius-lg flex flex-col gap-5 px-6 py-8 lg:max-w-[608px] lg:basis-[50%] lg:gap-7 xl:max-w-[688px] xl:px-16 xl:py-16',
        ['bg-ecruwhite', 'bg-steelblue', 'bg-lightgray'][index % 3],
      )}
    >
      <p className='h4 text-center xl:text-left'>
        {loading ? <LoadingText textLength={15} /> : data.title}
      </p>
      <div className='flex flex-col gap-4 text-center xl:text-left [&>*]:!mb-0'>
        {loading ? <LoadingText textLength={25} lines={5} /> : data.description}
      </div>
    </div>
  </div>
)
