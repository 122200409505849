import Link from 'next/link'

import Button from '../../components/Button'
import { BlogPostCardType } from '../../types'
import BlogPostCard from '../BlogPostCard'

type FeatureBlogPostsProps = {
  title: string
  linkToBlogText: string
  linkToBlogURL: string
  posts: BlogPostCardType[]
}

export default function FeatureBlogPosts({
  title,
  linkToBlogText,
  linkToBlogURL,
  posts,
}: FeatureBlogPostsProps) {
  return (
    <div className='flex w-full flex-col px-5 py-10 xl:px-10 xl:py-20'>
      <div className='mb-14 flex flex-row items-center'>
        <p className='h2 flex-1'>{title}</p>

        <Link href={linkToBlogURL} className='hidden no-underline xl:block'>
          <p className='underline-animated'>{linkToBlogText}</p>
        </Link>
      </div>

      <div className='grid w-full grid-cols-1 gap-5 xl:grid-cols-3'>
        {posts.map(blogPost => (
          <BlogPostCard
            data={{ post: blogPost }}
            smallImageSizes='(min-width: 1280px) 50vw, 100vw'
            size='small'
            key={blogPost.id}
          />
        ))}
      </div>

      <Button className='mt-8 block !max-w-full xl:hidden' href={linkToBlogURL} appearance='light'>
        {linkToBlogText}
      </Button>
    </div>
  )
}
