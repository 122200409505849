import clsx from 'clsx'
import { Image } from 'cms-types'

import Icon from '../../components/Icon'
import LoadingText from '../../components/LoadingText'
import { PayloadImage } from '../../components/PayloadImage'
import { LoadableComponentProps } from '../../types'

type HelpCardProps = LoadableComponentProps<{
  title: string
  description: string
  backgroundColor?: string | null
  telephone?: {
    text?: string | null
    icon?: string | null
  }
  email?: {
    text?: string | null
    icon?: string | null
  }
  image?: Image
}>

export default function HelpCard({ data, loading }: HelpCardProps) {
  return (
    <div className='px-5 py-10 xl:px-10 xl:py-20'>
      <div
        className={clsx(
          'flex flex-col-reverse overflow-hidden rounded-lg xl:flex-1 xl:flex-row xl:gap-20',
          !data?.backgroundColor ? 'bg-steelblue' : '',
        )}
        style={data?.backgroundColor ? { backgroundColor: data.backgroundColor } : {}}
      >
        <div
          className={clsx(
            'flex flex-col items-center px-5 py-8 xl:items-start xl:p-14',
            loading || data?.image != null
              ? 'gap-10 xl:gap-12'
              : 'w-full gap-9 xl:flex-row xl:gap-20',
          )}
        >
          <div
            className={clsx(
              'flex flex-col',
              loading || data?.image ? 'gap-6 xl:gap-7' : 'gap-4 xl:w-[640px]',
            )}
          >
            <p className='h4 text-center xl:text-start'>
              {loading ? <LoadingText inline textLength={20} /> : data.title}
            </p>

            <p className={clsx('sm text-center lg:!text-[24px] xl:text-start')}>
              {loading ? (
                <>
                  <LoadingText inline textLength={25} />
                  <br />
                  <LoadingText inline textLength={35} className='mt-2' />
                </>
              ) : (
                data.description
              )}
            </p>
          </div>

          <div className='flex h-full flex-col items-center justify-center gap-5 xl:items-start xl:gap-6'>
            {data?.telephone?.text != null && (
              <a
                href={`tel:${data.telephone.text}`}
                className='no-underline transition-colors duration-300 hover:text-darkgray'
              >
                <div className='flex items-center gap-2'>
                  {data.telephone.icon && <Icon aria-hidden name={data.telephone.icon} />}

                  <p className='sm underline underline-offset-8 lg:!text-[20px]'>
                    {data.telephone.text}
                  </p>
                </div>
              </a>
            )}

            {data?.email?.text != null && (
              <a
                href={`mailto:${data.email.text}`}
                className='no-underline transition-colors duration-300 hover:text-darkgray'
              >
                <div className='flex items-center gap-2'>
                  {data.email.icon && <Icon aria-hidden name={data.email.icon} />}

                  <p className='sm underline underline-offset-8 lg:!text-[20px]'>
                    {data.email.text}
                  </p>
                </div>
              </a>
            )}
          </div>
        </div>

        {(loading || data?.image != null) && (
          <div className='flex h-[280px] min-w-[50%] overflow-hidden xl:h-auto xl:flex-1'>
            <PayloadImage
              src={data?.image}
              sizes='(min-width: 1280px) 50vw, 100vw'
              objectFit='cover'
              className='transition-all'
              showPlaceholder={loading}
            />
          </div>
        )}
      </div>
    </div>
  )
}
