import { Image } from 'cms-types'

import Button from '../../components/Button'
import { PayloadImage } from '../../components/PayloadImage'

type SuggestionCardProps = {
  title: string
  description: string
  buttonText: string
  image?: Image
  url?: string
}

export default function SuggestionCard({
  title,
  description,
  buttonText,
  image,
  url,
}: SuggestionCardProps) {
  return (
    <div className='mx-5 my-6 flex flex-col overflow-hidden rounded-lg bg-lightgray xl:mx-10 xl:my-14 xl:flex-row-reverse'>
      <PayloadImage
        src={image}
        objectFit='cover'
        sizes='100vw'
        className='!h-[280px] xl:!h-[454px] xl:flex-1'
      />

      <div className='xl:flex-1 xl:pr-[0.05px]'>
        <div className='flex flex-col gap-10 px-5 py-6 xl:gap-14 xl:py-12 xl:pl-14 xl:pr-20'>
          <div className='flex flex-col gap-6'>
            <h4 className='h4 text-center sm:text-left'>{title}</h4>

            <p className='text-center sm:text-left'>{description}</p>
          </div>

          <Button
            href={url ?? undefined}
            appearance='transparent'
            className='w-full !max-w-full xl:!max-w-max'
          >
            {buttonText}
          </Button>
        </div>
      </div>
    </div>
  )
}
