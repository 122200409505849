import LoadingText from '../../components/LoadingText'
import type { LoadableComponentProps } from '../../types'

type BlogTipCardProps = {
  text: (JSX.Element | null)[]
}

export default function BlogTipCard({ data, loading }: LoadableComponentProps<BlogTipCardProps>) {
  return (
    <div className='rounded-md bg-ecruwhite p-5 xl:p-10 '>
      {loading ? (
        <div className='flex flex-col items-center gap-5'>
          <LoadingText className='w-[30%]' />
          <LoadingText className='w-[50%]' />
          <LoadingText className='w-[40%]' />
        </div>
      ) : (
        data.text
      )}
    </div>
  )
}
