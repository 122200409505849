import clsx from 'clsx'

import { PayloadImage } from '../../components/PayloadImage'
import { PayloadVideo } from '../../components/PayloadVideo'
import isPayloadImage from '../../helpers/isPayloadImage'
import { Media } from '../../types'

type HeroFeaturesProps = {
  title: string
  topics: { title: string; description: string }[]
  mediaDesktop?: Media
  mediaMobile?: Media
  gapBetweenTitleAndTopics: number
}

export default function HeroFeatures({
  title,
  topics,
  mediaDesktop,
  mediaMobile,
  gapBetweenTitleAndTopics,
}: HeroFeaturesProps) {
  return (
    <div className='isolate mx-auto px-5 pt-10 xl:px-10 xl:pb-10 xl:pt-0'>
      <div className='radius-md relative overflow-hidden'>
        {/* Desktop section */}

        <div className='absolute z-10 hidden h-full w-full justify-center px-16 pb-[60px] pt-20 xl:flex'>
          <div className='relative h-full w-full max-w-[1280px]'>
            <h2 className='h2 w-[400px] flex-grow'>{title}</h2>

            {topics.slice(0, 3).map(({ title, description }, index) => (
              <div
                key={index}
                className={clsx(
                  'absolute flex max-w-[330px] flex-col gap-3',
                  {
                    0: 'right-0 top-[59px]',
                    1: 'bottom-0 left-0',
                    2: 'bottom-0 right-0',
                  }[index],
                )}
              >
                <h5 className='h5'>{title}</h5>

                <p className='sm'>{description}</p>
              </div>
            ))}
          </div>
        </div>

        <div className='hidden max-h-[700px] xl:block'>
          {isPayloadImage(mediaDesktop) ? (
            <PayloadImage
              src={mediaDesktop}
              sizes='100vw'
              objectFit='cover'
              className='!h-[700px]'
            />
          ) : (
            <PayloadVideo
              autoPlay
              src={mediaDesktop}
              className='h-full max-h-[700px] w-full object-cover'
            />
          )}
        </div>

        {/* Mobile section */}
        <div
          className={`relative z-20 flex h-full w-full flex-col px-5 py-6 xl:hidden`}
          style={{ gap: gapBetweenTitleAndTopics }}
        >
          <h3 className='h3 flex-grow text-center'>{title}</h3>

          <div className='flex flex-col items-center gap-6'>
            {topics.slice(0, 3).map(({ title, description }, index) => (
              <div
                key={index}
                className='flex flex-col items-center gap-3 sm:max-w-[60%] lg:max-w-[50%]'
              >
                <h5 className='h5'>{title}</h5>

                <p className='tag text-center'>{description}</p>
              </div>
            ))}
          </div>
        </div>

        <div className='absolute left-0 top-0 h-full w-full xl:hidden'>
          {isPayloadImage(mediaMobile) ? (
            <PayloadImage src={mediaMobile} sizes='100vw' objectFit='cover' />
          ) : (
            <PayloadVideo
              autoPlay
              src={mediaMobile}
              containerClassName='h-full'
              className='h-full w-full object-cover'
            />
          )}
        </div>
      </div>
    </div>
  )
}
