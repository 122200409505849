import { InfoCardListBlock } from 'cms-types'
import Link from 'next/link'

import { LoadableComponentProps } from '../../types'
import Button from '../Button'
import Icon from '../Icon'
import LoadingText from '../LoadingText'
import { InfoCard, InfoCardType } from './InfoCard'

type MappedInfoCard = Pick<InfoCardType, 'id' | 'title' | 'image'> & {
  description: (JSX.Element | null)[]
  tags: {
    text: string
  }[]
}

type Props = LoadableComponentProps<
  Pick<InfoCardListBlock, 'title' | 'toggleHeightLabels' | 'collapsibleCards'> & {
    pageLink?: {
      title?: string
      url?: string
    }
    cards: MappedInfoCard[]
  }
>

export default function InfoCardList({ data, loading }: Props) {
  return (
    <section className='my-20 px-5 md:mt-10 xl:px-10'>
      <div className='flex items-center justify-between gap-14'>
        <h2 className='h4'>{loading ? <LoadingText textLength={15} /> : data.title}</h2>
        {data?.title && data?.pageLink && data?.pageLink.url && (
          <Link href={data.pageLink.url} className='hidden w-max shrink-0 no-underline md:block'>
            <p className='flex items-center gap-2 border-b-2 border-black text-xl transition-colors duration-300 hover:border-darkgray hover:text-darkgray'>
              {data.pageLink.title}

              <Icon name='MdEast' size={16} />
            </p>
          </Link>
        )}
      </div>

      <ul className='flex flex-col gap-10 pt-10 md:grid md:grid-cols-2 md:gap-5 lg:grid-cols-3'>
        {loading
          ? new Array(3).fill(null).map((_, index) => (
              <li key={index}>
                <InfoCard
                  loading
                  imageSizes='(min-width: 640px) 50vw, (min-width: 1024px) 33vw, 100vw'
                />
              </li>
            ))
          : data.cards.map(({ id, ...restCard }) => (
              <li key={id}>
                <InfoCard
                  data={{
                    ...restCard,
                    collapsible: data.collapsibleCards,
                    toggleHeightLabels: data.toggleHeightLabels,
                  }}
                  imageSizes='(min-width: 640px) 50vw, (min-width: 1024px) 33vw, 100vw'
                />
              </li>
            ))}
      </ul>
      {!data?.title && data?.pageLink && data?.pageLink.url && (
        <Link
          href={data.pageLink.url}
          className='flex w-full shrink-0 justify-start pt-10 no-underline lg:justify-end lg:pt-5'
        >
          <p className='flex items-center gap-2 border-b-2 border-black text-xl transition-colors duration-300 hover:border-darkgray hover:text-darkgray'>
            {data.pageLink.title}

            <Icon name='MdEast' size={16} />
          </p>
        </Link>
      )}
      {data?.title && data?.pageLink && data?.pageLink.url && (
        <Button
          className='mt-10 !max-w-fit md:hidden'
          href={data.pageLink.url}
          appearance='transparent'
        >
          {data.pageLink.title}
        </Button>
      )}
    </section>
  )
}
