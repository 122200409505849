import clsx from 'clsx'

import Button from '../../components/Button'
import LoadingText from '../../components/LoadingText'
import { PayloadImage } from '../../components/PayloadImage'
import { PayloadVideo } from '../../components/PayloadVideo'
import isPayloadImage from '../../helpers/isPayloadImage'
import { LoadableComponentProps, Media } from '../../types'

type AboutCardProps = LoadableComponentProps<{
  title: string
  description?: string | null
  buttonText: string
  media?: Media
  url?: string
  invertedDesignColors?: boolean | null
}>

export default function AboutCard({ loading, data }: AboutCardProps) {
  return (
    <div className={clsx('flex px-5', data?.invertedDesignColors ? 'bg-lightgray' : 'bg-white')}>
      <div
        className={clsx(
          'mx-auto my-16 flex w-full flex-col-reverse gap-10 overflow-hidden rounded-lg bg-lightgray lg:flex-row lg:gap-16 xl:mx-10 xl:my-20 xl:h-[640px] xl:gap-20',
          data?.invertedDesignColors ? 'bg-white' : 'bg-lightgray',
        )}
      >
        <div className='flex h-[409px] overflow-hidden lg:h-[640px] lg:basis-[40%] xl:flex-1'>
          {loading ? (
            <LoadingText className='h-[409px] w-full object-cover transition-all lg:h-[640px]' />
          ) : isPayloadImage(data.media) ? (
            <PayloadImage
              sizes='(min-width: 1024px) 40vw, 100vw'
              src={data.media}
              objectFit='cover'
              className='transition-all'
            />
          ) : (
            <PayloadVideo
              src={data.media}
              className='h-[409px] w-full object-cover transition-all lg:h-[640px]'
            />
          )}
        </div>

        <div className='lg:flex lg:basis-[60%] lg:items-center xl:flex-1 xl:pl-[0.05px]'>
          <div className='flex flex-col gap-10 px-5 pt-8 xl:gap-14 xl:py-20 xl:pr-20'>
            <div className='flex flex-col gap-6 xl:gap-7'>
              <h2 className='h2 text-center lg:text-start'>
                {loading ? <LoadingText textLength={15} /> : data.title}
              </h2>

              {loading ? (
                <LoadingText textLength={30} />
              ) : (
                data.description && <p className='lg'>{data.description}</p>
              )}
            </div>

            <Button
              isLoading={loading}
              href={data?.url ?? '#'}
              appearance='primary'
              className='mx-auto w-full lg:mx-0 lg:w-fit'
            >
              {data?.buttonText}
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}
