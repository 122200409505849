import clsx from 'clsx'
import { Image } from 'cms-types'
import React from 'react'

import { PayloadImage } from '../../components/PayloadImage'

type ImageSplitViewProps = {
  content: {
    image?: Image
    caption: string
  }[]
}

export default function ImageSplitView({ content }: ImageSplitViewProps) {
  return (
    <div className='grid grid-cols-1 gap-5 xl:my-10 xl:grid-cols-2'>
      {content.map(({ image, caption }, index) => (
        <div
          key={index}
          className={clsx('col-span-1 mb-5', {
            'xl:last:col-span-2': content.length % 2 === 1,
          })}
        >
          <div className='mb-4 w-full'>
            <PayloadImage
              src={image}
              sizes='(min-width: 903px) 903px, 100vw'
              className='flex justify-center'
              imgProps={{
                className:
                  'max-h-[240px] xl:max-h-[540px] w-auto max-w-full rounded-md transition-all',
              }}
            />
          </div>

          <p className='tag text-center'>{caption}</p>
        </div>
      ))}
    </div>
  )
}
