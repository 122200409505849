import clsx from 'clsx'
import { Image } from 'cms-types'

import Button from '../../components/Button'
import { PayloadImage } from '../../components/PayloadImage'

type ProductHintCardProps = {
  title?: string | null
  description?: string | null
  buttonText?: string | null
  image?: Image
  url?: string | null
  openInNewTab?: boolean | null
  isFullWidth?: boolean | null
}

export default function ProductHintCard({
  title,
  description,
  buttonText,
  image,
  url,
  openInNewTab,
  isFullWidth,
}: ProductHintCardProps) {
  return (
    <div
      className={clsx(
        'group my-6 flex flex-col items-stretch overflow-hidden rounded-lg bg-lightgray xl:my-14 xl:flex-row-reverse',
        {
          'mx-5 xl:mx-10': !isFullWidth,
        },
      )}
    >
      <div className='h-[300px] overflow-hidden xl:h-auto xl:flex-[2]'>
        <PayloadImage
          sizes='(min-width: 903px) 903px, 100vw'
          src={image}
          objectFit='cover'
          className='block transition-all duration-[0.8s] ease-[ease] group-hover:scale-105 xl:flex-[2]'
        />
      </div>

      <div className='xl:flex-[3] xl:pr-[0.05px]'>
        <div className='flex flex-col gap-10 px-5 py-6 xl:gap-14 xl:py-12 xl:pl-14 xl:pr-20'>
          <div className='flex flex-col gap-6'>
            <h4 className='h4'>{title}</h4>

            <p>{description}</p>
          </div>

          <Button
            href={url ?? undefined}
            target={openInNewTab ? '_blank' : '_self'}
            appearance='transparent'
            className='w-full !max-w-full xl:!max-w-max'
          >
            {buttonText ?? ''}
          </Button>
        </div>
      </div>
    </div>
  )
}
