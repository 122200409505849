import { Image } from 'cms-types'

import { PayloadImage } from '../../components/PayloadImage'

type HeroInfoBlockProps = {
  items: Array<{
    image?: Image
    title: string
    description: string
    url: string
  }>
}

export default function HeroInfoBlock({ items: heroItems }: HeroInfoBlockProps) {
  return (
    <ul className='grid grid-cols-1 gap-5 p-5 xl:grid-cols-3 xl:gap-10 xl:p-10'>
      {heroItems.map(({ image, title, description, url }, index) => (
        <li
          key={index}
          className='radius-sm border border-solid border-lightgray shadow-md !transition-all duration-1000 xl:focus-within:-translate-y-3 xl:focus-within:shadow-2xl xl:hover:-translate-y-3 xl:hover:shadow-2xl'
        >
          <a href={url} className='flex h-full flex-col p-5 no-underline xl:p-10'>
            <p className='lg pb-5 text-center'>{title}</p>

            <PayloadImage src={image} sizes='(min-width: 1280px) 33vw, 100vw' className='pb-10' />

            <p className='sm line-clamp-6 text-center'>{description}</p>
          </a>
        </li>
      ))}
    </ul>
  )
}
