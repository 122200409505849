import { Image } from 'cms-types'

import LoadingText from '../../components/LoadingText'
import { PayloadImage } from '../../components/PayloadImage'
import { LoadableComponentProps } from '../../types'

type PageHeaderProps = LoadableComponentProps<{
  title: string
  description: (JSX.Element | null)[]
  image: Image | undefined
  priority?: boolean
}>

export default function PageHeader({ data, loading }: PageHeaderProps) {
  return (
    <div className='flex flex-col gap-10 px-5 py-10 xl:flex-row xl:items-center xl:gap-[100px] xl:px-10'>
      <div className='flex flex-col gap-4 md:max-w-[560px] lg:gap-8 xl:max-w-[640px] xl:py-12'>
        <h1 className='h2'>{loading ? <LoadingText textLength={15} /> : data.title}</h1>
        <div className='h6 flex flex-col gap-4 lg:gap-3 [&>*]:!mb-0 lg:[&>*]:[&>*]:!text-[24px]'>
          {loading ? <LoadingText textLength={25} /> : data.description}
        </div>
      </div>
      <PayloadImage
        priority={data?.priority}
        src={data?.image}
        sizes='100vw'
        className='radius-lg !h-[400px] overflow-hidden xl:!h-[700px]'
        objectFit='cover'
        showPlaceholder={loading}
      />
    </div>
  )
}
