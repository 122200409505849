import { type Image, type Video } from 'cms-types'

import LoadingText from '../../components/LoadingText'
import { PayloadImage } from '../../components/PayloadImage'
import { PayloadVideo } from '../../components/PayloadVideo'
import { LoadableComponentProps } from '../../types'

type Props = LoadableComponentProps<{
  title: string
  description: (JSX.Element | null)[]
  video: Video | undefined
  projectsTitle?: string | null
  projects?: {
    image: Image | undefined
    backgroundColor?: string | null
    mixBlendMode?: 'normal' | 'multiply' | 'overlay' | 'darken' | 'lighten' | null
  }[]
}>

export default function Engagement({ data, loading }: Props) {
  return (
    <section className='flex flex-col items-center gap-12 px-5 pb-10 pt-20  xl:px-10'>
      <div className='flex max-w-[640px] flex-col gap-y-4 text-center [&>*]:!mb-0'>
        <h2 className='h3'>{loading ? <LoadingText inline textLength={15} /> : data.title}</h2>
        {loading ? <LoadingText inline textLength={25} lines={5} /> : data.description}
      </div>

      {loading ? (
        <div>
          <LoadingText className='radius-lg aspect-video h-[540px] w-full' />
        </div>
      ) : (
        data?.video && (
          <div>
            <PayloadVideo
              muted={false}
              controls
              preload='none'
              controlsList='nodownload'
              src={data.video}
              className='radius-lg h-auto w-full lg:max-h-[540px]'
            />
          </div>
        )
      )}
      {(loading || data?.projects) && (
        <div className='flex flex-col gap-6 self-start xl:self-center'>
          <span className='h6 xl:text-center'>
            {loading ? <LoadingText inline textLength={15} /> : data.projectsTitle}
          </span>
          <ul className='flex flex-wrap gap-2 xl:justify-center xl:gap-[25px]'>
            {loading
              ? new Array(4).fill(null).map((_, index) => (
                  <li
                    key={`loading-${index}`}
                    className='radius-lg h-[120px] w-[160px] bg-lightgray px-2 py-4'
                  >
                    <PayloadImage skipBlur sizes='144px' objectFit='contain' showPlaceholder />
                  </li>
                ))
              : data.projects?.map(({ image, backgroundColor, mixBlendMode }, index) => (
                  <li
                    key={image?.id ?? index}
                    className='radius-lg h-[120px] w-[160px] bg-lightgray px-2 py-4'
                    style={backgroundColor ? { backgroundColor } : undefined}
                  >
                    <PayloadImage
                      skipBlur
                      src={image}
                      sizes='144px'
                      objectFit='contain'
                      pictureStyle={mixBlendMode ? { mixBlendMode } : undefined}
                    />
                  </li>
                ))}
          </ul>
        </div>
      )}
    </section>
  )
}
